.notFoundContainer {
    background-color: #a02929;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    width: 100%;
    padding: 20px; 
    box-sizing: border-box; 
}
.notFoundTitle {
    margin-bottom: 20px;
    font-size: 4rem; 
}

.notFoundText {
    margin-bottom: 20px; 
}

.notFoundLink {
    color: #fff;
    text-decoration: underline;
    padding: 10px 20px;
    transition: background-color 0.3s;
    border-radius: 5px;
}

.notFoundLink:hover {
    background-color: #2c3e50;
}
