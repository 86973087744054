.header {
    background-color: #4BC0C8; 
    color: white;
    width: 100%;
    position: fixed; 
    top: 0;
    left: 0;
    overflow: visible;
    z-index: 1000; 
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease-in-out; 
}

.header-link, .header-select {
    text-decoration: none;
    margin-left: 15px;
    font-weight: bold;
    transition: transform 0.2s ease-in-out; 
    background-color: transparent; 
    
    border: none; 
    outline: none; 
}

.header-link:hover, .header-select:hover {
    text-decoration: underline;
    transform: scale(1.1); 
}

.header-select {
    padding: 5px 10px; 
    cursor: pointer; 
}