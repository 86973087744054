.couriers-container {
    margin-top: 50px;
    background-color: #34495e;
    color: #fff;
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.couriers-title {
    margin-bottom: 20px;
}

.couriers-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
}

.couriers-list li {
    padding: 15px;
    border-bottom: 1px solid #fff;
}

.courier-link {
    color: #fff; 
    text-decoration: none; 
    display: flex;
    align-items: center;
    transition: background-color 0.3s; 
}

.courier-link:hover {
    background-color: #2c3e50; 
}


.couriers-list li:last-child {
    border-bottom: none;
}

.icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-repeat: no-repeat;
}

.inactive {
    cursor: default;
}

.inactive:hover {
    background-color: transparent;
}

