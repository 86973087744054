.home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #007BFF; 
    min-height: 100vh;
    width: 100%; 
    padding: 20px; 
    box-sizing: border-box;
}

.home-page h1 {
    color: #8b3444; 
    font-size: 2em; 
    margin-bottom: 0.5em; 
}

.home-page .home-form, .home-page .home-info {
    background: rgb(189, 197, 235);
    padding: 25px; 
    border-radius: 10px; 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left; 
    width: 90%; 
    max-width: 500px;
}

.home-page input, .home-page select {
    width: 100%;
    height: 40px; 
    padding: 8px 12px; 
    border: 1px solid #ccc; 
    border-radius: 8px; 
    box-sizing: border-box; 
    margin-bottom: 10px; 
}

.home-page button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s, box-shadow 0.2s; 
    margin-top: 20px;
}

.home-page button:hover, .home-page button:focus {
    background-color: #0056b3; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.home-page select {
    -webkit-appearance: none; 
    -moz-appearance: none;    
    appearance: none;     
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns="http://www.w3.org/2000/svg"%20width="14"%20height="12"%20viewBox="0%200%2020%2016"><path%20fill="%234C4C4C"%20d="M7.41%208.84L12.58%203.66%2014%205.08%207.41%2011.66%201%205.08%202.42%203.66z"/></svg>'); /* Custom arrow icon */
    background-repeat: no-repeat;
    background-position: right 10px center; 
    background-size: 14px 12px; 
}
