.api-container {
    background-color: #16a085; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure it covers at least the full height of the viewport */
    width: 100%; /* Ensure it covers the full width of the viewport */
    padding: 20px; /* Adjust padding as needed */
    box-sizing: border-box; /* Includes padding in width/height calculations */
}

.api-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.api-content {
    font-size: 1.2rem;
}

.api-link {
    color: #f39c12; 
    text-decoration: none;
}
